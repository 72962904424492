import * as React from 'react';
import moment from 'moment';
import * as Constants from './Constants';
import _ from 'lodash';
import { FilesColumns, getColNames } from '../components/Users/helpers';
import { backendUrl } from '../Routing/Urls';
import { RequestsTableVacationStatusesConstants } from '../components/Vacations/Constants';
import { RequestsTableExcuseStatusesConstants } from '../components/Excuses/Constants';
import { handleRequestErrors } from './Requests';

const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;

export const downloadBlob = (content, filename, contentType) => {
  // Create a blob
  const blob = new Blob([content], { type: contentType });
  const url = URL.createObjectURL(blob);

  // Create a link to download it
  const pom = document.createElement('a');
  pom.href = url;
  pom.setAttribute('download', filename);
  pom.click();
};

export const getDayNameFromDate = (dateString) => {
  return moment(dateString).format('dddd');
};

export const getAttributeValuesFromArray = (array, attribute) => {
  const attributeValues = [];
  array.forEach((ele) => {
    attributeValues.push(ele[attribute]);
  });
  return attributeValues;
};

export const can = (permissions) => {
  // Allow if permissions array is empty
  if (!permissions.length) return true;

  const userPermissions = Constants.userData.role.permissions;
  // Check if any of the permissions in the array is present in userPermissions
  return permissions.some((permission) =>
    userPermissions.some(
      (userPermission) => userPermission.name === permission,
    ),
  );
};

export const userHasAnyRole = (roles) => {
  const userRole = Constants.userData.role;
  return roles.some((role) => userRole.name === role);
};

export const getVacationDaysFromRequestsByType = (vacationRequests, typeId) => {
  const filteredByTypeRequests = vacationRequests.filter(
    (request) => request.vacationType.id == typeId,
  );
  let vacationsCount = 0;
  if (filteredByTypeRequests.length) {
    for (const request of filteredByTypeRequests) {
      vacationsCount += request.total_days;
    }
  }
  return vacationsCount;
};

export const injectVacationsStatusStyle = (Vacations) => {
  const updatedVacations = Vacations.map((vacation) => {
    // adding style to the status
    const statusStyle =
      _.find(RequestsTableVacationStatusesConstants, {
        id: vacation.status.id,
      }) || {};
    vacation.status.style = statusStyle.style;
    return vacation;
  });
  return updatedVacations;
};

export const injectExcusesStatusStyle = (Excuses) => {
  const updatedExcuses = Excuses.map((excuse) => {
    // adding style to the status
    const statusStyle =
      _.find(RequestsTableExcuseStatusesConstants, {
        id: excuse.status.id,
      }) || {};
    excuse.status.style = statusStyle.style;
    return excuse;
  });
  return updatedExcuses;
};

export const createStatusBadgeDiv = (statusName, bgStyle, textStyle) => {
  return <span className={`badge ${bgStyle} ${textStyle}`}>{statusName}</span>;
};

export const getYearlyWeekends = (weekends) => {
  // get the weekends for 3 years range (1 year before and 1 year after the current year)
  const yearFirstDay = moment().subtract(1, 'year').startOf('year');
  const yearLastDay = moment().add(1, 'year').endOf('year');
  const yearlyWeekends = [];

  weekends.forEach((weekendDay) => {
    const iterationDay = yearFirstDay.clone();
    while (iterationDay.day(weekendDay.id).isSameOrBefore(yearLastDay)) {
      const weekendDay = iterationDay.clone().format('Y-MM-DD');
      const weekendDayObject = {
        start_date: weekendDay,
        end_date: weekendDay,
        name: 'Weekend',
      };
      yearlyWeekends.push(weekendDayObject);
      iterationDay.add(1, 'week'); // Move to the next week
    }
  });

  return yearlyWeekends;
};

export const getHolidayIncludingADay = (day, holidays) => {
  const overlappingObject = holidays.find((holiday) => {
    return (
      day.start_date >= holiday.start_date && day.end_date <= holiday.end_date
    );
  });
  return overlappingObject;
};

export const filterDuplicateHolidays = (yearlyWeekends, nationalHolidays) => {
  const filteredYearlyWeekends = [];
  yearlyWeekends.forEach((weekend) => {
    if (!getHolidayIncludingADay(weekend, nationalHolidays)) {
      filteredYearlyWeekends.push(weekend);
    }
  });
  return filteredYearlyWeekends;
};

export const injectHolidaysType = (holidays, vacationTypeId, types) => {
  const vacationType = _.find(types, { id: vacationTypeId }) || {};
  return holidays.map((holiday) => {
    return {
      ...holiday,
      name: vacationType.display_name,
    };
  });
};

export const getDaysByDuration = (startDay, endDay) => {
  const days = [];
  const startDate = moment(startDay);
  const endDate = moment(endDay);
  const diffInDays = endDate.diff(startDate, 'days');
  const numberOfDays = diffInDays + 1;

  for (let i = 0; i < numberOfDays; i++) {
    const day = moment(startDay).add(i, 'days').format('YYYY-MM-DD');
    days.push({
      start_date: day,
      end_date: day,
    });
  }

  return days;
};

export const getVacationsByDuration = (
  startDay,
  endDay,
  vacationTypeId,
  types,
) => {
  const days = getDaysByDuration(startDay, endDay);
  const daysWithType = injectHolidaysType(days, vacationTypeId, types);
  return daysWithType;
};

export const getTotalDeductedDays = (days, holidays) => {
  let totalDeductedDays = 0;
  days.forEach((day) => {
    totalDeductedDays += getHolidayIncludingADay(day, holidays) ? 0 : 1;
  });
  return totalDeductedDays;
};

export const updateDaysTypeWithHolidays = (days, holidays) => {
  const updatedDays = [];
  days.forEach((day) => {
    if (getHolidayIncludingADay(day, holidays)) {
      const holiday = getHolidayIncludingADay(day, holidays);
      updatedDays.push({
        ...day,
        name: holiday.name,
      });
    } else {
      updatedDays.push({
        ...day,
      });
    }
  });
  return updatedDays;
};

export const formatObjectKeys = (obj, map) => {
  const newObj = {};
  const keyOrder = Object.keys(map).map((key) => map[key].Name);

  for (const key of keyOrder) {
    if (Object.hasOwnProperty.call(obj, key)) {
      const mappingEntry = _.find(map, { Name: key });

      // If a mapping entry is found, assign the new key to the value from the Label property
      // Otherwise, use the original key
      const newKey = mappingEntry ? mappingEntry.Label : key;

      newObj[newKey] = obj[key];
    }
  }

  return newObj;
};

export const filterObjNullValues = (obj) => {
  const filteredObject = {};

  for (const key in obj) {
    if (
      Object.prototype.hasOwnProperty.call(obj, key) &&
      obj[key] !== undefined &&
      obj[key] !== null
    ) {
      filteredObject[key] = obj[key];
    }
  }

  return filteredObject;
};

export const nullifyObjectValues = (obj) => {
  for (const key of Object.keys(obj)) {
    obj[key] = null;
  }
  return obj;
};

export const formatUserDataToForm = (managedUserData) => {
  for (const key of Object.keys(managedUserData)) {
    if (typeof managedUserData[key] === 'object') {
      managedUserData[key] = managedUserData[key].id;
    }
  }
  return managedUserData;
};

export const getAllExcept = (array, elementsToExclude) => {
  return array.filter((item) => !elementsToExclude.includes(item));
};

export const handleViewFile = (link) => {
  window.open(link, '_blank');
};

export const formatFileLink = (userData) => {
  const FilesColumnsNames = getColNames(FilesColumns);
  FilesColumnsNames.forEach((fileColumnName) => {
    if (userData[fileColumnName]) {
      userData[fileColumnName] = userData[fileColumnName].replace(
        backendUrl,
        window.location.origin,
      );
    }
  });
  return userData;
};

export const formatFilesLinks = (usersData) => {
  return usersData.map((userData) => {
    return formatFileLink(userData);
  });
};

export const getTableByType = (type, title) => {
  return Constants.getManagedRequestsTables(title).find(
    (table) => table.type == type,
  );
};

export const getTableById = (id, title) => {
  return Constants.getManagedRequestsTables(title).find(
    (table) => table.id == id,
  );
};

export const handleDateFieldsChange = (
  date,
  fieldName,
  dateFields,
  setInvalidDate,
  formData,
  setFormData,
) => {
  const InvalidDateLibConst = 'Invalid Date';
  let updatedFormData = {};

  if (date && date.format('YYYY-MM-DD') !== InvalidDateLibConst) {
    setInvalidDate(false);
    updatedFormData = {
      ...formData,
      [fieldName]: date.format('YYYY-MM-DD'),
    };
  } else {
    setInvalidDate(true);
    updatedFormData = {
      ...formData,
      [fieldName]: InvalidDateLibConst,
    };
  }

  // Checking if all date fields in the form are valid.
  let invalidDateFlag = false;
  Object.values(dateFields).forEach((dateField) => {
    // if field has value and this value not matching date pattern
    if (
      updatedFormData[dateField.Name] &&
      !dateFormatRegex.test(updatedFormData[dateField.Name])
    ) {
      invalidDateFlag = true;
    }
  });

  setInvalidDate(invalidDateFlag);
  setFormData(updatedFormData);
};

export const handleDateFieldChange = (date, setDate, setInvalidDate) => {
  const InvalidDateLibConst = 'Invalid Date';

  if (date && date.format('YYYY-MM-DD') !== InvalidDateLibConst) {
    setInvalidDate(false);
    setDate(date.format('YYYY-MM-DD'));
  } else {
    setInvalidDate(true);
    setDate(InvalidDateLibConst);
  }
};

export const createFilterQueryByFields = (filterFields) => {
  let queryString = '';

  // handling status field special case
  if (filterFields['status[]']) {
    for (const statusId of filterFields['status[]']) {
      queryString += `status[]=${statusId}&`;
    }
    delete filterFields['status[]'];
  }

  for (const [key, value] of Object.entries(filterFields)) {
    queryString += `${key}=${value}&`;
  }

  return queryString;
};

export const splitDecimalDurationToHrsAndMins = (decimalDuration) => {
  const hours = Math.floor(decimalDuration);
  const minutes = Math.round((decimalDuration - hours) * 60);
  return [hours, minutes];
};

export function useEffectAsync(effect, dependencies) {
  React.useEffect(() => {
    const effectFn = async () => {
      await effect();
    };

    effectFn();
  }, dependencies);
}

export function isValidDateString(dateString) {
  return dateFormatRegex.test(dateString);
}

export async function catchRequestError(promise) {
  try {
    return await promise;
  } catch (error) {
    handleRequestErrors(error);
  }
}

export const createStatusesQuery = (statuses) => {
  let queryString = '';
  statuses.forEach((status) => (queryString += `status[]=${status}&`));
  return queryString;
};

export const downloadFile = (response, contentType, fileExtension) => {
  // Extract the filename from the Content-Disposition header
  const contentDisposition = response.headers['content-disposition'];
  const filename = contentDisposition
    ? contentDisposition.split('filename=')[1].replace(/"/g, '')
    : `payment_report.${fileExtension}`;

  const blob = new Blob([response.data], { type: contentType });
  const url = window.URL.createObjectURL(blob);

  // Create an anchor element and click it to trigger the download
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();

  // Cleanup: remove the anchor element and revoke the object URL
  link.parentNode.removeChild(link);
  window.URL.revokeObjectURL(url);
};
