import * as React from 'react';

import Calendar from 'rc-year-calendar';

import 'tippy.js/dist/tippy.css'; // optional for styling
import tippy from 'tippy.js';
import 'tippy.js/themes/light.css';

import structuredClone from '@ungap/structured-clone';
import { useNavigate } from 'react-router-dom';

import './Dashboard.css';

import * as Requests from '../../Helpers/Requests';
import * as Helpers from '../../Helpers/Helpers';
import * as Constants from '../../Helpers/Constants';
import {
  createEmptyOriginalObj,
  longToIso,
  originalToRendered,
  renderedToOriginal,
} from './DashboardUtils';
import DashboardViewModal from './DashboardViewModal';
import DashboardDeleteModal from './DashboardDeleteModal';
import {
  BALANCE_REQUEST_STATUSES,
  PENDING_STATUSES_VACATIONS,
  STATUSES_VACATIONS,
  VACATIONTYPES,
} from '../Vacations/Constants';

export default function Dashboard() {
  const navigate = useNavigate();
  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>//
  // >>>>>>>>>>>>>>>>>>>>>>> Vacations Data >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>//
  const [showModal, setShowModal] = React.useState(null);
  const [renderedData, setRenderedData] = React.useState([]);
  const [originalData, setOriginalData] = React.useState([]);
  const [vacationTypesBalances, setVacationTypesBalances] = React.useState([]);
  const [vacationTypesBalancesLoaded, setVacationTypesBalancesLoaded] =
    React.useState(false);
  const [currentVacation, setCurrentVacation] = React.useState({});
  const [vacationsUpdated, setVacationsUpdated] = React.useState(false);

  let tooltip = null;
  // <<<<<<<<<<<<<<<<<<<<<<< Vacations Data <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<//
  // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<//
  React.useEffect(() => {
    const RequiredStatuses = [
      BALANCE_REQUEST_STATUSES.Approved,
      BALANCE_REQUEST_STATUSES.Pending,
    ];

    Requests.getUserVacationBalances(Constants.userData.id, RequiredStatuses)
      .then((_vacationTypesBalances) => {
        setVacationTypesBalances(_vacationTypesBalances);
        setVacationTypesBalancesLoaded(true);
      })
      .catch((err) => Requests.handleRequestErrors(err));

    const filterFieldsVacations = {
      'status[]': [...PENDING_STATUSES_VACATIONS, STATUSES_VACATIONS.Approved],
    };

    Requests.getUserVacations(Constants.userData.id, filterFieldsVacations)
      .then((vacations) => {
        setOriginalData(vacations.data);
      })
      .catch((err) => Requests.handleRequestErrors(err));
  }, [vacationsUpdated]);

  React.useEffect(() => {
    // Convert date format to match the calendar library requirement.
    let _renderedData = originalToRendered(originalData);
    _renderedData = Helpers.injectVacationsStatusStyle(_renderedData);
    setRenderedData(_renderedData);
  }, [originalData]);

  const handleDayEnter = (e) => {
    if (e.events.length > 0) {
      const content = `
          <div class="event-tooltip-content">
            <div
              style="
			    color:${e.events[0].color};
			  ">
              ${e.events[0].reason}
            </div>
          </div>
        `;

      tooltip = tippy(e.element, {
        allowHTML: true,
        placement: 'right',
        content,
        animateFill: false,
        animation: 'shift-away',
        arrow: true,
        theme: 'light',
      });
      tooltip.show();
    }
  };

  const handleDayLeave = () => {
    if (tooltip) {
      tooltip.destroy();
      tooltip = null;
    }
  };

  const handleDayClick = (e) => {
    if (e.events.length) {
      setShowModal('view');
      let _currentVacation = createEmptyOriginalObj();
      _currentVacation = {
        ..._currentVacation,
        ...structuredClone(e.events[0]),
      };
      setCurrentVacation(renderedToOriginal([_currentVacation])[0]);
    }
  };
  const customRenderEvent = (div, dateString, event) => {
    div.style.borderRadius = '0px';

    if (event[0].status.id == STATUSES_VACATIONS.Approved) {
      div.style.backgroundColor = event[0].vacationType.color;

      if (['#737373', 'orange'].includes(event[0].vacationType.color)) {
        div.style.color = 'black';
      }
    } else {
      div.style.boxShadow = `inset 0 -4px 0 0 ${event[0].vacationType.color}`;
    }
  };

  return (
    renderedData &&
    Object.keys(Constants.userData).length > 0 &&
    vacationTypesBalancesLoaded && (
      <>
        <div
          id="dashboard_main"
          className="d-flex justify-content-center align-items-center h-100 flex-column"
        >
          <div className="h2 mb-5 d-flex align-items-center">
            Your Vacation Summary of {new Date().getFullYear()}
          </div>
          <div className="row mb-4 w-100 justify-content-evenly align-items-top">
            <div
              className="py-3 badge col-lg-2 align-self-end"
              style={{
                maxWidth: '170px',
                backgroundColor: 'white',
                color: 'green',
                boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
              }}
            >
              <h5 className="mt-2 mb-3">Annual</h5>
              <p className="h6 fw-normal text-dark">
                Booked
                {' : '}
                {vacationTypesBalances[VACATIONTYPES.Normal.Name].used}
              </p>
              <p className="h6 fw-normal text-dark">
                Available
                {' : '}
                {vacationTypesBalances[VACATIONTYPES.Normal.Name].remaining}
              </p>
            </div>
            <div
              className="py-3 badge col-lg-2"
              style={{
                maxWidth: '170px',
                backgroundColor: 'white',
                color: '#737373',
                boxShadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.2)',
              }}
            >
              <h5 className="mt-2 mb-3">Unpaid</h5>
              <p className="h6 fw-normal text-dark">
                Booked
                {' : '}
                {vacationTypesBalances[VACATIONTYPES.Unpaid.Name].used}
              </p>
              <p className="h6 fw-normal text-dark">
                Available
                {' : '}
                {vacationTypesBalances[VACATIONTYPES.Unpaid.Name].remaining}
              </p>
            </div>
          </div>
          <Calendar
            dataSource={renderedData}
            enableRangeSelection={true}
            enableContextMenu={false}
            displayWeekNumber={true}
            weekStart={6}
            // allowOverlap={false}
            style="custom"
            customDataSourceRenderer={(div, dateString, event) =>
              customRenderEvent(div, dateString, event)
            }
            onDayClick={(e) => handleDayClick(e)}
            onDayEnter={(e) => handleDayEnter(e)}
            onDayLeave={() => handleDayLeave()}
            onRangeSelected={(e) =>
              setCurrentVacation({
                start_date: e.start_date,
                end_date: e.end_date,
              })
            }
          />
          {currentVacation.vacationType && (
            <>
              <DashboardViewModal
                showModal={showModal}
                setShowModal={setShowModal}
                currentVacation={currentVacation}
              />
              <DashboardDeleteModal
                showModal={showModal}
                setShowModal={setShowModal}
                originalData={originalData}
                setOriginalData={setOriginalData}
                currentVacation={currentVacation}
                setVacationsUpdated={setVacationsUpdated}
                vacationsUpdated={vacationsUpdated}
              />
            </>
          )}
        </div>
        <div className="w-100 d-flex justify-content-end pe-5 mb-4">
          <button
            className="btn btn-success btn-lg mt-3 text-white"
            onClick={() => navigate(Constants.Pages.RequestVacation.path())}
          >
            Request a Vacation
          </button>
        </div>
      </>
    )
  );
}
