import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import Home from '../components/Home';
import PermissionProtectedRoutes from './PermissionProtectedRoutes';
import {
  PERMISSIONS,
  Pages,
  ROLES,
  SystemManagersRoles,
  TeamsHighManagers,
} from '../Helpers/Constants';
import RoleProtectedRoutes from './RoleProtectedRoutes';

export default function Routing() {
  return (
    <>
      <div style={{ paddingTop: '60px', minHeight: '100vh' }}>
        <Routes>
          <Route path="" element={<Home />}>
            <Route index element={<Navigate to="/login" />} />
            <Route element={<RoleProtectedRoutes roles={[ROLES.Admin]} />}>
              <Route
                path={Pages.LoginAs.path()}
                element={Pages.LoginAs.Element}
              />
            </Route>
            <Route path={Pages.Files.path()} element={Pages.Files.Element} />
            <Route
              path={Pages.Dashboard.path()}
              element={Pages.Dashboard.Element}
            />
            <Route
              path={Pages.Profile.path()}
              element={Pages.Profile.Element}
            />
            <Route
              path={Pages.RequestVacation.path()}
              element={Pages.RequestVacation.Element}
            />
            <Route
              element={<RoleProtectedRoutes roles={SystemManagersRoles} />}
            >
              <Route
                path={Pages.CreateVacation.path()}
                element={Pages.CreateVacation.Element}
              />
            </Route>
            <Route
              path={Pages.RequestExcuse.path()}
              element={Pages.RequestExcuse.Element}
            />
            <Route
              element={
                <PermissionProtectedRoutes
                  permissions={[PERMISSIONS.CreateOvertime]}
                />
              }
            >
              <Route
                path={Pages.RequestOvertime.path()}
                element={Pages.RequestOvertime.Element}
              />
              <Route
                element={<RoleProtectedRoutes roles={TeamsHighManagers} />}
              >
                <Route
                  path={Pages.CreateOvertime.path()}
                  element={Pages.CreateOvertime.Element}
                />
              </Route>
              <Route
                path={Pages.OvertimeView.path()}
                element={Pages.OvertimeView.Element}
              />
            </Route>
            <Route
              path={Pages.OneTimePaymentsView.path()}
              element={Pages.OneTimePaymentsView.Element}
            />
            <Route
              element={
                <PermissionProtectedRoutes
                  permissions={[PERMISSIONS.CreatePaymentAdjustment]}
                />
              }
            >
              <Route
                path={Pages.PaymentAdjustmentRequest.path()}
                element={Pages.PaymentAdjustmentRequest.Element}
              />
              <Route
                element={<RoleProtectedRoutes roles={SystemManagersRoles} />}
              >
                <Route
                  path={Pages.PaymentAdjustmentCreate.path()}
                  element={Pages.PaymentAdjustmentCreate.Element}
                />
              </Route>
              <Route
                path={Pages.OneTimePaymentsRequested.path()}
                element={Pages.OneTimePaymentsRequested.Element}
              />
            </Route>
            <Route
              path={Pages.RecurringPaymentsView.path()}
              element={Pages.RecurringPaymentsView.Element}
            />
            <Route
              element={
                <PermissionProtectedRoutes
                  permissions={[PERMISSIONS.CreateRecurringPayments]}
                />
              }
            >
              <Route
                path={Pages.RecurringPaymentsRequest.path()}
                element={Pages.RecurringPaymentsRequest.Element}
              />
            </Route>
            <Route
              element={
                <PermissionProtectedRoutes
                  permissions={[PERMISSIONS.ManageRecurringPayments]}
                />
              }
            >
              <Route
                path={Pages.RecurringPaymentsManage.path()}
                element={Pages.RecurringPaymentsManage.Element}
              />
            </Route>
            <Route
              element={
                <PermissionProtectedRoutes
                  permissions={[PERMISSIONS.ViewPaymentReport]}
                />
              }
            >
              <Route
                path={Pages.PaymentReport.path()}
                element={Pages.PaymentReport.Element}
              />
            </Route>
            <Route
              path={Pages.VacationsView.path()}
              element={Pages.VacationsView.Element}
            />
            <Route
              path={Pages.UserVacations.path(':userId')}
              element={Pages.UserVacations.Element}
            />
            <Route
              path={Pages.VacationsReport.path()}
              element={Pages.VacationsReport.Element}
            />
            <Route
              path={Pages.NursingHours.path()}
              element={Pages.NursingHours.Element}
            />
            <Route
              path={Pages.PayrollView.path()}
              element={Pages.PayrollView.Element}
            />
            <Route
              path={Pages.NursingScheduleRequested.path()}
              element={Pages.NursingScheduleRequested.Element}
            />
            <Route
              element={
                <PermissionProtectedRoutes
                  permissions={[PERMISSIONS.ManagePayrollHistory]}
                />
              }
            >
              <Route
                path={Pages.PayrollHistory.path()}
                element={Pages.PayrollHistory.Element}
              />
            </Route>
            <Route
              element={
                <PermissionProtectedRoutes
                  permissions={[PERMISSIONS.CreateNursingSchedule]}
                />
              }
            >
              <Route
                path={Pages.NursingScheduleRequest.path()}
                element={Pages.NursingScheduleRequest.Element}
              />
            </Route>
            <Route
              element={
                <PermissionProtectedRoutes
                  permissions={[PERMISSIONS.ManageNursingSchedule]}
                />
              }
            >
              <Route
                path={Pages.NursingScheduleManage.path()}
                element={Pages.NursingScheduleManage.Element}
              />
            </Route>
            <Route
              path={Pages.ExcusesView.path()}
              element={Pages.ExcusesView.Element}
            />
            <Route
              path={Pages.UserExcuses.path(':userId')}
              element={Pages.UserExcuses.Element}
            />
            <Route
              element={
                <PermissionProtectedRoutes
                  permissions={[PERMISSIONS.ManageUsers]}
                />
              }
            >
              <Route
                path={Pages.UsersAll.path()}
                element={Pages.UsersAll.Element}
              />
              <Route
                path={Pages.UsersTerminated.path()}
                element={Pages.UsersTerminated.Element}
              />
            </Route>
            <Route
              element={
                <PermissionProtectedRoutes
                  permissions={[PERMISSIONS.ManageStatusUpdates]}
                />
              }
            >
              <Route
                path={Pages.StatusUpdates.path(':userId')}
                element={Pages.StatusUpdates.Element}
              />
            </Route>
            <Route
              element={
                <PermissionProtectedRoutes
                  permissions={[PERMISSIONS.ManageVacations]}
                />
              }
            >
              <Route
                path={Pages.VacationsManage.path()}
                element={Pages.VacationsManage.Element}
              />
            </Route>
            <Route
              element={
                <PermissionProtectedRoutes
                  permissions={[PERMISSIONS.ManageVacations]}
                />
              }
            >
              <Route
                path={Pages.ExcusesManage.path()}
                element={Pages.ExcusesManage.Element}
              />
            </Route>
            <Route
              element={
                <PermissionProtectedRoutes
                  permissions={[PERMISSIONS.ManageOvertime]}
                />
              }
            >
              <Route
                path={Pages.OvertimeManage.path()}
                element={Pages.OvertimeManage.Element}
              />
            </Route>
            <Route
              element={
                <PermissionProtectedRoutes
                  permissions={[PERMISSIONS.ManagePaymentAdjustment]}
                />
              }
            >
              <Route
                path={Pages.PaymentAdjustmentManage.path()}
                element={Pages.PaymentAdjustmentManage.Element}
              />
            </Route>
            <Route
              element={
                <PermissionProtectedRoutes
                  permissions={[PERMISSIONS.ViewAnyLoans]}
                />
              }
            >
              <Route
                path={Pages.LoansList.path()}
                element={Pages.LoansList.Element}
              />
            </Route>
            <Route
              element={
                <PermissionProtectedRoutes
                  permissions={[PERMISSIONS.CreateLoans]}
                />
              }
            >
              <Route
                path={Pages.LoansRequest.path()}
                element={Pages.LoansRequest.Element}
              />
            </Route>
            <Route
              element={
                <PermissionProtectedRoutes
                  permissions={[PERMISSIONS.ManageLoans]}
                />
              }
            >
              <Route
                path={Pages.LoansManageRequests.path()}
                element={Pages.LoansManageRequests.Element}
              />
            </Route>
            <Route
              element={
                <PermissionProtectedRoutes
                  permissions={[PERMISSIONS.ManageLoans]}
                />
              }
            >
              <Route
                path={Pages.LoansManageRunning.path()}
                element={Pages.LoansManageRunning.Element}
              />
            </Route>
            <Route
              element={
                <PermissionProtectedRoutes
                  permissions={[PERMISSIONS.ManagePaymentReport]}
                />
              }
            >
              <Route
                path={Pages.PaymentSetting.path()}
                element={Pages.PaymentSetting.Element}
              />
            </Route>
            <Route
              path={Pages.WorkLogsView.path()}
              element={Pages.WorkLogsView.Element}
            />
            <Route
              path={Pages.Holidays.path()}
              element={Pages.Holidays.Element}
            />
          </Route>
          <Route
            path={Pages.Login.path()}
            name={Pages.Login.Title}
            element={Pages.Login.Element}
          />
          <Route
            path={Pages.NotFound.path()}
            name={Pages.NotFound.Title}
            element={Pages.NotFound.Element}
          />
        </Routes>
      </div>
    </>
  );
}
